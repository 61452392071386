import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

function ProtectedRoute({ token = null, component: Component, ...rest }) {
  console.log("called", token);
  return (
    <Route
      {...rest}
      render={(props) =>
        !!token ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect
            to={{ pathname: "/home", state: { from: props.location } }}
          />
        )
      }
    />
  );
}

export default connect(({ auth: { userDetails = {} } }) => {
  let { token } = userDetails;
  return { token };
})(ProtectedRoute);
