import React, { Component } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { ConnectedRouter as Router } from "connected-react-router";
import { history } from "./store/store";
import ProtectedRoute from "./protectedRoute";
import RedirectRoute from "./redirectRoute";
import { connect } from "react-redux";
import Login from "./controllers/login";
import Home from "./views/home";
import Dashboard from "./views/dashboard";
import {
  Backdrop,
  CircularProgress,
  makeStyles,
  Snackbar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Registration from "./controllers/registration/createMerchant";
import _SignUp from "./controllers/registration/signup";
import EmailOTP from "./views/registration/emailOTP";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function SimpleBackdrop({ open }) {
  const classes = useStyles();
  console.log(open, "open");
  return (
    <div>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
class Routes extends Component {
  render() {
    return (
      <Router history={history}>
        <SimpleBackdrop open={this.props.showLoader} />
        <Switch>
          <Route
            path="/"
            render={(props) => (
              <Redirect
                to={{ pathname: "/portal", state: { from: props.location } }}
              ></Redirect>
            )}
            exact
          />
          <ProtectedRoute path="/portal" component={Dashboard} />
          <RedirectRoute path={`/login`} component={Login} />
          <RedirectRoute path={`/create`} component={Registration} />
          <RedirectRoute path="/home" component={Home} />
          <Route path="/signup" component={_SignUp} />
          <Route path="/email-otp" component={EmailOTP} />
        </Switch>
        {this.props.toaster ? (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={this.props.toaster ? true : false}
            autoHideDuration={3000}
          >
            <Alert variant="filled" severity={this.props.severity || "error"}>
              {this.props.errorMessage}
            </Alert>
          </Snackbar>
        ) : (
          <React.Fragment></React.Fragment>
        )}
      </Router>
    );
  }
}
export default connect(
  ({
    auth,
    loader: {
      showLoader,
      toaster,
      errorMessage = "Something went wrong",
      severity,
    },
  }) => {
    let { userDetails } = auth;
    return {
      userDetails,
      showLoader,
      toaster,
      errorMessage,
      severity,
    };
  },
  null
)(Routes);
