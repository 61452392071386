import { connect } from "react-redux";
import {
  createDiscount,
  deleteDiscount,
  editDiscount,
  fileUpload,
  getDiscountList,
} from "../../store/actions/rewards";
import {
  DISCOUNTS_VIEW_SCREEN,
  DISCOUNT_ADD_SCREEN,
  DISCOUNT_EDIT_SCREEN,
} from "../../store/types/rewards";
import moment from "moment";
import Discount_View from "../../views/rewards/discount";

class Discount extends Discount_View {
  onChange = (e) => {
    this.setState({ filter: e.target.value });
  };

  onAdd = () => {
    this.setState({ action: DISCOUNT_ADD_SCREEN });
  };

  onInputChange = (key) => (e) => {
    this.setState({
      [key]:
        key === "discount"
          ? Number(e.target.value) <= 99
            ? { value: e.target.value }
            : { value: this.state.discount.value }
          : { value: e.target.value },
    });
  };

  handleDayClick = (key, label = "") => (date) => {
    if (isNaN(Date.parse(date))) {
      this.setState({
        [key]: {
          value: date,
          isError: true,
          errorMessage: `${label} should be in a valid format`,
        },
      });
      return;
    }
    this.setState({
      [key]: { value: date },
    });
  };

  async componentDidMount() {
    await this.props.getDiscountList();
  }

  onFileUpload = (key) => async (event) => {
    const { files = [] } = event.target;
    if (files.length) {
      let res = await this.props.fileUpload(files[0]);
      if (!!res.data) {
        this.setState({ [key]: { value: res.data.url } });
      }
    }
  };

  onSetDiscount = (isEditCall) => async () => {
    const body = {
      // title: this.state.addTitle.value,
      discount: Number(this.state.discount.value),
      fromDate: moment(this.state.fromDate.value).startOf("day").toISOString(),
      toDate: moment(this.state.toDate.value).endOf("day").toISOString(),
      discription: this.state.description.value,
      displayLogoId: this.state.displayLogoId.value,
      spotlightImageId: this.state.spotlightImageId.value,
    };

    if (!this.state.discount.value || this.state.discount.value < 3) {
      this.setState({
        discount: {
          ...this.state.discount,
          isError: true,
          errorMessage: !this.state.discount.value
            ? "Discount value cannot be empty"
            : "Please enter valid discount amount between 3% to 99%",
        },
      });
      return;
    }
    // if (this.state.description.value === "none") {
    //   this.setState({
    //     description: {
    //       ...this.state.description,
    //       isError: true,
    //       errorMessage: "Please select description",
    //     },
    //   });
    //   return;
    // }

    if (
      new Date(this.state.toDate.value).getTime() <
        new Date(this.state.fromDate.value).getTime() ||
      this.state.fromDate.isError ||
      this.state.toDate.isError
    ) {
      this.setState({
        toDate: {
          ...this.state.toDate,
          isError: true,
          errorMessage: this.state.fromDate.isError
            ? "Valid From Date should be in a valid format"
            : this.state.toDate.isError
            ? "Valid To Date should be in a valid format"
            : " Valid To Date should be after the  Valid From date ",
        },
      });
      return;
    }

    if (!this.state.displayLogoId.value) {
      this.setState({
        displayLogoId: {
          ...this.state.displayLogoId.value,
          isError: true,
          errorMessage: "File size should not be more than 1MB",
        },
      });
      return;
    }

    let res = isEditCall
      ? await this.props.editDiscount(this.state.editDiscountId, body)
      : await this.props.createDiscount(body);
    if (res) {
      this.setState({ action: DISCOUNTS_VIEW_SCREEN, ...this.getInitState() });
    }
  };

  onEdit = ({
    discount,
    discription,
    displayLogoId,
    spotlightImageId,
    toDate,
    fromDate,
    id,
  }) => async () => {
    this.setState({
      action: DISCOUNT_EDIT_SCREEN,
      discount: { value: discount },
      displayLogoId: { value: displayLogoId },
      description: { value: discription },
      spotlightImageId: { value: spotlightImageId },
      toDate: { value: toDate },
      fromDate: { value: fromDate },
      editDiscountId: id,
    });
    // await this.props.editDiscount(id);
  };

  onDelete = (id) => async () => {
    await this.props.deleteDiscount(id);
    await this.props.getDiscountList();
  };

  filterDiscounts = (items = []) => {
    const filetredList = items.reduce((prev, curr) => {
      const start = new Date(moment().startOf("day")).getTime();
      const fromTime = new Date(moment(curr.fromDate).startOf("day")).getTime();
      const endTime = new Date(moment(curr.toDate).endOf("day")).getTime();
      if (fromTime - start > 0) {
        return { ...prev, ["upcoming"]: [...(prev.upcoming || []), curr] };
      } else if (start - endTime > 0) {
        return { ...prev, ["archived"]: [...(prev.expired || []), curr] };
      } else {
        return { ...prev, ["current"]: [...(prev.current || []), curr] };
      }
    }, {});
    return filetredList;
  };
}

export default connect(
  ({ rewardsReducer, loader }) => {
    const { discounts = [] } = rewardsReducer;
    const { showLoader } = loader;
    return { discounts, showLoader };
  },
  {
    fileUpload,
    createDiscount,
    getDiscountList,
    editDiscount,
    deleteDiscount,
  }
)(Discount);
