import { combineReducers } from "redux";
import auth from "./auth";
import loaderReducer from "./loader";
import rewardsReducer from "./rewards";

export default (history) => {
  return combineReducers({
    router: history,
    auth,
    loader: loaderReducer,
    rewardsReducer,
  });
};
