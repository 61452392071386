import React from "react";
import { connect } from "react-redux";
import {
  getAccountBalance,
  getTipBalance,
  transferBalance,
} from "../../store/actions/balance";
import Balance from "../../views/balance/balance";

class _Balance extends Balance {
  async componentDidMount() {
    let { data = {} } = (await this.props.getAccountBalance()) || {};
    let { data: tipBalance = {} } = (await this.props.getTipBalance()) || {};
    console.log(tipBalance, "--->");
    this.setState({ balance: { ...data, tipBalance } });
  }

  setAmount = (e) => {
    if (
      Number(e.target.value) >
      Number(parseFloat(this.state.balance.account_balance))
    ) {
      this.setState({
        amount: {
          ...this.state.amount,
          isError: true,
          errorMessage: "Insufficient balance to withdraw",
        },
      });
      return;
    }
    this.setState({ amount: { value: e.target.value } });
  };

  transfer = async () => {
    if (Number(this.state.amount.value) > 0) {
      let res =
        (await this.props.transferBalance({
          amount: Number(this.state.amount.value),
        })) || {};
      if (!!res.success) {
        let { data = {} } = (await this.props.getAccountBalance()) || {};
        this.setState({
          balance: { ...this.state.balance, ...data },
          amount: { value: "" },
        });
      }
    } else {
      this.setState({
        amount: {
          ...this.state.amount,
          isError: true,
          errorMessage: "Transfer amount should be greater than $ 0",
        },
      });
    }
  };
}
export default connect(null, {
  getAccountBalance,
  transferBalance,
  getTipBalance,
})(_Balance);
