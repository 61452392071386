import { START_LOADING, STOP_LOADING } from "../types/loader";
import {
  LOGOUT_SUCCESS,
  SHOW_TOAST,
  SIGN_UP_SUCCESS,
  STOP_TOAST,
} from "../types/auth";

const initialState = {
  showLoader: false,
  toaster: 0,
  otpToken: null,
};

export default function loaderReducer(state = initialState, action) {
  switch (action.type) {
    case START_LOADING: {
      return { ...state, showLoader: ++state.showLoader };
    }
    case STOP_LOADING: {
      return { ...state, showLoader: Math.max(0, --state.showLoader) };
    }
    case SHOW_TOAST: {
      const toaster = ++state.toaster;
      return {
        ...state,
        toaster,
        errorMessage: action.message,
        severity: action.severity || null,
      };
    }
    case STOP_TOAST: {
      const toaster = Math.max(0, --state.toaster);
      return { ...state, toaster };
    }
    case SIGN_UP_SUCCESS: {
      const { body = {} } = action;
      return {
        ...state,
        otpToken: (body.data || {}).otpToken,
      };
    }
    case LOGOUT_SUCCESS: {
      return { showLoader: false, toaster: 0 };
    }
    default:
      return state;
  }
}
