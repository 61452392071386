import React from "react";
import { Button, Typography } from "@material-ui/core";
import { COLORS, FONTS } from "../../constants";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { SelectInput, TextInput } from "../../forms/textinput";
import DatePicker from "../../forms/datePicker";
// import moment from "moment";

import {
  addDiscount,
  contentWrapper,
  inputWrapper,
  eachInput,
  setBtn,
  loyaltyWrapper,
  loyaltyAdd,
  loyaltyBtn,
} from "./rewardStyle";
import {
  LOYALTY_VIEW_SCREEN,
  LOYALTY_ADD_SCREEN,
  LOYALTY_EDIT_SCREEN,
} from "../../store/types/rewards";
import moment from "moment";

export default class Loyalty extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...this.getInitState() };
  }
  getInitState = () => {
    return {
      action: LOYALTY_VIEW_SCREEN,
      spend: { value: "" },
      days: { value: "" },
      fromDate: { value: new Date() },
      toDate: { value: new Date() },
      // fromDate: { value: new Date(new Date().getTime() - 1000 * 60 * 60 * 24) },
      //toDate: { value: new Date(new Date().getTime() - 1000 * 60 * 60 * 24) },
      reward: { value: "" },
      editLoyaltyId: null,
    };
  };

  getDays = (toDate, fromDate) => {
    const a = moment(moment(toDate).format("MM-DD-YYYY"));
    const b = moment(moment(fromDate).format("MM-DD-YYYY"));
    const c = a.diff(b, "days");
    return c+1;
  };

  render() {
    return (
      <div style={loyaltyWrapper} className="loyalty_wrapper">
        {this.state.action === LOYALTY_VIEW_SCREEN && (
          <Button
            onClick={this.onAdd}
            style={loyaltyAdd}
            className="fontBold add_tierBtn"
          >
            {" "}
            + Add
          </Button>
        )}
        {this.state.action === LOYALTY_VIEW_SCREEN ? (
          <>
            <div
              style={{ marginTop: "70px" }}
              className="tableWrapper table_desktop"
            >
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead style={{ backgroundColor: COLORS.table_head }}>
                    <TableRow>
                      <TableCell>$ Spent</TableCell>
                      <TableCell>No. of Days</TableCell>
                      <TableCell>Reward(% of $ spend)</TableCell>
                      <TableCell>Valid From</TableCell>
                      <TableCell>Valid To</TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.props.loyalty.map((item, index) => (
                      <TableRow key={`row-${index + 1}`}>
                        <TableCell>{item.spend}</TableCell>
                        <TableCell>
                          {this.getDays(item.toDate, item.fromDate)}
                        </TableCell>
                        {/* <TableCell>{`${item.days} days`}</TableCell> */}
                        <TableCell>{`${item.reward}%`}</TableCell>
                        <TableCell>
                          {moment(item.fromDate).format("l")}
                        </TableCell>
                        <TableCell>{moment(item.toDate).format("l")}</TableCell>
                        <TableCell onClick={this.onEdit(item)}>
                          <p
                            style={{ color: COLORS.juma_theme_clr }}
                            className="cursor fontBold"
                          >
                            {moment(item.fromDate).format("MM DD YYYY") >
                              moment(new Date()).format("MM DD YYYY") && "EDIT"}
                          </p>
                        </TableCell>
                        <TableCell onClick={this.onDeleteLoyalty(item.id)}>
                          <p
                            style={{ color: COLORS.juma_theme_clr }}
                            className="cursor fontBold"
                          >
                            {moment(item.fromDate).format("MM DD YYYY") <=
                              moment(new Date()).format("MM DD YYYY") &&
                            moment(item.toDate).format("MM DD YYYY") >=
                              moment(new Date()).format("MM DD YYYY")
                              ? null
                              : "DELETE"}
                            {/* DELETE */}
                          </p>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

            {/* mobile table start */}
            <div style={{ marginTop: 70 }}>
              {this.props.loyalty.map((item, index) => (
                <div className="tableWrapper table_mobile">
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead style={{ backgroundColor: COLORS.table_head }}>
                        <TableRow>
                          <TableCell>$ Spent</TableCell>
                          <TableCell>No. of Days</TableCell>
                          <TableCell>Reward(% of $ spend)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key={`row-${index + 1}`}>
                          <TableCell>{item.spend}</TableCell>
                          <TableCell>{`${item.days} days`}</TableCell>
                          <TableCell>{`${item.reward}%`}</TableCell>
                        </TableRow>
                      </TableBody>
                      <TableHead style={{ backgroundColor: COLORS.table_head }}>
                        <TableRow>
                          <TableCell>Valid From</TableCell>
                          <TableCell>Valid To</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody class="date_table_border_bottom">
                        <TableRow key={`row-${index + 1}`}>
                          <TableCell>
                            {moment(item.fromDate).format("l")}
                          </TableCell>
                          <TableCell>
                            {moment(item.toDate).format("l")}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              ))}
            </div>
            {/* mobile table end */}
            {/* <p
              className="textCenter"
              style={{ fontSize: FONTS.size_medium, marginTop: "50px" }}
            >
              You have set a loyalty rewards that rewards $10 in cashback if the
              customer spends $200 in a 30 day time frame
            </p> */}
            <div className="textCenter lotality_btn_sec">
              <Button style={loyaltyBtn} className="fontBold loyalty_btn">
                View total Loyalty by Spend
              </Button>
            </div>
          </>
        ) : (
          <LoyaltyAdd
            handleDateChange={this.handleDayClick}
            onInputChange={this.onInputChange}
            onFileUpload={this.onFileUpload}
            onCreateLoyalty={this.onCreateLoyalty}
            goBack={() =>
              this.setState({
                action: LOYALTY_VIEW_SCREEN,
                ...this.getInitState(),
              })
            }
            {...this.state}
          />
        )}
      </div>
    );
  }
}

export function LoyaltyAdd(props) {
  const {
    spend = {},
    reward = {},
    fromDate = {},
    toDate = {},
    days = {},
    action,
  } = props;
  return (
    <div style={addDiscount} className="add_discount discount_sec">
      <p className="fontBold" style={{ display: "flex" }}>
        {" "}
        <img
          onClick={props.goBack}
          src="/images/back.svg"
          style={{ marginRight: "15px", marginTop: "2px", cursor: "pointer" }}
        />{" "}
        {action === LOYALTY_EDIT_SCREEN ? "Edit Loyalty" : " Set New Loyalty"}
      </p>
      <div style={contentWrapper}>
        <div style={inputWrapper} class="new_discount_main">
          <div style={eachInput} class="new_discount_ip edit-wrapper">
            <TextInput
              style={{ width: "100%" }}
              onChange={props.onInputChange(`spend`)}
              value={spend.value}
              label={"$ Spend"}
              errorMessage={spend.errorMessage}
              isError={spend.isError}
              type="number"
            ></TextInput>
          </div>
          <div style={eachInput} class="new_discount_ip edit-wrapper">
            <TextInput
              style={{ width: "100%" }}
              onChange={props.onInputChange(`reward`)}
              value={reward.value}
              label={"Reward(% of $ spend )"}
              errorMessage={reward.errorMessage}
              isError={reward.isError}
              type="number"
            ></TextInput>
          </div>
        </div>
        {/* <div style={inputWrapper} class="new_discount_main">
          <div style={eachInput} class="new_discount_ip edit-wrapper">
            <TextInput
              style={{ width: "100%" }}
              onChange={props.onInputChange(`days`)}
              value={days.value}
              label={"No of Days"}
              errorMessage={days.errorMessage}
              isError={days.isError}
              type="number"
            ></TextInput>
          </div>
          <div style={eachInput} class="new_discount_ip edit-wrapper">
            <TextInput
              style={{ width: "100%" }}
              onChange={props.onInputChange(`reward`)}
              value={reward.value}
              label={"Reward(% of $ spend )"}
              errorMessage={reward.errorMessage}
              isError={reward.isError}
              type="number"
            ></TextInput>
          </div>
        </div> */}
        <div style={inputWrapper} class="new_discount_main">
          <div style={eachInput} class="new_discount_ip">
            <DatePicker
              handleDateChange={props.handleDateChange(
                "fromDate",
                "Valid From Date"
              )}
              selectedDate={fromDate.value}
              minDate={new Date()}
              // minDate={new Date(new Date().getTime() + 1000 * 60 * 60 * 24)}
              label="Valid From Date"
              errorMessage={fromDate.errorMessage}
              isError={fromDate.isError}
            />
          </div>
          <div style={eachInput} class="new_discount_ip">
            <DatePicker
              handleDateChange={props.handleDateChange(
                "toDate",
                "Valid To Date"
              )}
              selectedDate={toDate.value}
              minDate={new Date()}
              // minDate={new Date(fromDate.value)}
              label="Valid To Date"
              errorMessage={toDate.errorMessage}
              isError={toDate.isError}
            />
          </div>
        </div>
        <div style={{ marginTop: 30 }}>
          <Button
            onClick={props.onCreateLoyalty(action === LOYALTY_EDIT_SCREEN)}
            color="Primary"
            style={setBtn}
          >
            {action === LOYALTY_EDIT_SCREEN ? "EDIT" : "SET"}
          </Button>
        </div>
      </div>
    </div>
  );
}
