import { Paper, Tab, Tabs, Typography } from "@material-ui/core";
import React from "react";
import Transfer from "../balance/transfer";
import GeneratePayment from "../balance/generate-payment";
import { COLORS, FONTS } from "../../constants";
import moment from "moment";

const amuontDetails = {
  display: "flex",
  justifyContent: "space-between",
  background: COLORS.default_white,
  marginBottom: "30px",
  boxSizing: "border-box",
  borderRadius: "10px",
};
const detailsItem = {
  background: COLORS.theme_light_blue,
  flex: "0 0 30.8%",
  padding: "15px",
  margin:0,
  borderRadius: "10px",
  boxSizing: "border-box",
  color: "#747474",
  fontSize: FONTS.size_medium,
};
const fullWidthItem = {
  background: COLORS.theme_light_blue,
  width : '100%',
  padding: "15px",
  margin:0,
  marginTop:8,
  borderRadius: "10px",
  boxSizing: "border-box",
  color: "#747474",
  fontSize: FONTS.size_medium,
}
const boldText = {
  color: COLORS.default_black,
};
export default class Balance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 1,
      balance: { tipBalance: {} },
      amount: { value: "" },
    };
  }

  render() {
    console.log(this.state, "--->state accountbalance");
    return (
      <div className="balance-wrapper balance_sec balance_wid_sec" >
        <Typography
          style={{
            marginBottom: 20,
            fontFamily: "NotoSans-Bold",
            marginTop: 12,
          }}
        >
          BALANCE
        </Typography>
        <div style={amuontDetails} className="amountBlockWrapper">
          <p style={detailsItem} className="balancePill">
            Available Balance{" "}
            <span style={boldText} className="fontBold current_font">
              {`$ ${this.state.balance.account_balance || 0}`}
            </span>
          </p>
          {/* <p style={detailsItem} className="tip_bottom">
            Tip Balance{" "}
            <span style={boldText} className="fontBold current_font">
              {`$ ${this.state.balance.tipBalance.account_balance || 0}`}
            </span>
          </p> */}
          <p  style={detailsItem} class="date_type">
            Date{" "}
            <span style={boldText} className="fontBold">
              {moment(new Date()).format("ll")}
            </span>
          </p>
          <p style={detailsItem} className="balancePill date_type  time_type">
            Time{" "}
            <span style={boldText} className="fontBold">
              {moment(new Date()).format("hh:mm a")}
            </span>
          </p>

          <p style={fullWidthItem} className="balancePill">
           Pending Balance{" "}
            <span style={boldText} className="fontBold current_font">
              {`$ ${this.state.balance.pending_balance || 'N/A'}`}
            </span>
          </p>

          <p style={fullWidthItem} className="balancePill">
           Total Balance{" "}
            <span style={boldText} className="fontBold current_font">
              {`$ ${this.state.balance.total_balance || 0}`}
            </span>
          </p>
        </div>

        <Paper square>
          <Tabs
            value={this.state.currentTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={this.handleChange}
          >
            <Tab value={1} label="Transfer" />
            <Tab value={2} label="Generate Payment Request" />
          </Tabs>
        </Paper>
        <Paper style={{ marginTop: 0, paddingBottom: 50 }} square>
          {this.state.currentTab === 1 ? (
            <Transfer
              amount={this.state.amount}
              onChange={this.setAmount}
              transferMoney={this.transfer}
            ></Transfer>
          ) : (
            <GeneratePayment></GeneratePayment>
          )}
        </Paper>
      </div>
    );
  }
}
