import React from "react";

export default class GeneratePayment extends React.Component {
    render() {
      return(
        <div>
             Generate Payment Request
        </div>
        )
    }
  }