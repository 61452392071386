import "date-fns";
import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

export default function DatePicker({
  label = "Date",
  selectedDate = new Date(),
  minDate = new Date("1900-01-01"),
  maxDate = new Date(`Jan 1, ${new Date().getFullYear()+5}`),
  errorMessage = "",
  isError = false,
  disableToolbar = true,
  ...rest
}) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        autoOk
        disableToolbar={disableToolbar}
        variant='inline'
        format="MM/dd/yyyy"
        margin="normal"
        label={label}
        value={selectedDate || new Date()}
        onChange={rest.handleDateChange}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        helperText={errorMessage}
        error={isError}
        minDate={minDate}
        maxDate={maxDate}
        // invalidDateMessage={<div>{`${label} should be in a valid format`}</div>}
      />
    </MuiPickersUtilsProvider>
  );
}
