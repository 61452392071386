import {
  AppBar,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
// import { BUTTON_GREEN_COLOR } from "../constants";
import { COLORS, FONTS, SIDENAV_ITEMS } from "../constants";
import "../App.css";
import { connect } from "react-redux";
import { onLogout } from "../store/actions/auth";
import LeftPanel, { leftNavStyles } from "./leftnav";

const headerNav = {
  display: "flex",
  alignItems: "center",
};
const navItem = {
  color: COLORS.juma_theme_clr,
  fontSize: FONTS.size_medium,
  margin: "0 15px",
  cursor: "pointer",
};

export function TextInput({
  label = "",
  value = "",
  errorMessage = "",
  isError = false,
  ...props
}) {
  return (
    <TextField
      value={value}
      label={label}
      id={label}
      defaultValue="Small"
      size="small"
      helperText={errorMessage}
      error={isError}
      {...props}
    />
  );
}

export function SelectInput({
  label = "",
  value = "",
  options = [],
  placeHolder,
  isError = false,
  errorMessage = "",
  ...props
}) {
  return (
    <FormControl style={{ minWidth: 250 }} error={isError}>
      <InputLabel id="demo-simple-select-error-label">{label}</InputLabel>
      <Select value={value} id={label} size="small" {...props}>
        {placeHolder && (
          <MenuItem value="none">
            {" "}
            <em>{placeHolder}</em>
          </MenuItem>
        )}
        {options.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {isError && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
}

export function Buttton({ label = "", style = {}, ...props }) {
  return (
    <div
      className="customBtn"
      {...props}
      style={{
        backgroundColor: COLORS.juma_theme_clr,
        padding: 8,
        color: COLORS.default_white,
        cursor: "pointer",
        ...style,
      }}
    >
      <Typography style={{ textAlign: "center" }}>{label}</Typography>
    </div>
  );
}

function Header({ header = "Jumna", token, ...props }) {
  const [show, showOverLay] = useState(false);
  const {
    leftNav,
    itemsContainer,
    eachNavItem,
    activeNavItem,
    navText,
    collapseIcon,
  } = leftNavStyles;
  const { path } = props;

  console.log(props, "--->hedaer");
  return (
    <div>
      <AppBar position="fixed">
        <Toolbar
          style={{
            justifyContent: "space-between",
            backgroundColor: COLORS.default_white,
          }}
        >
          <img src="/images/jumna_logo.svg" style={{ width: "120px" }} />
          <div className="webHeader">
            {token ? (
              <p
                onClick={() => props.onLogout()}
                className="fontBold"
                style={navItem}
              >
                Logout
              </p>
            ) : (
              <div style={headerNav}>
                <p className="fontBold" style={navItem}>
                  How it Works
                </p>
                <p className="fontBold" style={navItem}>
                  Jumna Advantage
                </p>
                <p
                  onClick={() => props.history.push("/create")}
                  className="fontBold"
                  style={navItem}
                >
                  Create Account
                </p>
                <p
                  onClick={() => props.history.push("/login")}
                  className="fontBold"
                  style={navItem}
                >
                  Business Sign-In
                </p>
              </div>
            )}

            {/* {!!isAuthorized && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography>Logout</Typography>
            </div>
          )} */}
          </div>
          <div className="mobileHeader">
            <img
              onClick={() => showOverLay(!show)}
              src={show ? "/images/close_menu.svg" : "/images/menu.svg"}
            />

            {/* Show hide this section for mobile menu */}
            {show && (
              <div className="menuOverlay">
                <div className="menuNavItems">
                  {/* <p className="fontBold mobileMenuItem">How it Works</p>
                  <p className="fontBold mobileMenuItem">Jumna Advantage</p>
                  <p className="fontBold mobileMenuItem">Create Account</p> */}

                  {token ? (
                    SIDENAV_ITEMS.map(
                      ({ activeImage, inactive, label, keyRoute }) => (
                        <div class="mobile_side_nav_item"
                          onClick={() => {
                            if (label === "Logout") {
                              props.onLogout();
                              return;
                            }
                            showOverLay(false);
                            props.history.push(`${path}${keyRoute}`);
                          }}
                          key={label}
                          style={{
                            ...eachNavItem,
                            ...activeNavItem,
                            borderColor: "white",
                            backgroundColor: "white",
                          }}
                        >
                          <img style={{ width: 18 }} src={activeImage} />
                          <p style={navText}>{label}</p>
                        </div>
                      )
                    )
                  ) : (
                    <p
                      onClick={() => props.history.push("/login")}
                      className="fontBold mobileMenuItem"
                    >
                      Business Sign-In
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default connect(
  ({ auth }) => {
    const { token } = auth.userDetails;
    return { token };
  },
  { onLogout }
)(Header);
