import { Paper, Tab, Tabs, Typography } from "@material-ui/core";
import React from "react";
import Discount from "../../controllers/rewards/discount";
import Loyalty from "../../controllers/rewards/loyalty";


export default class Rewards extends React.Component {
  constructor(props) {
    super(props);
    this.state = { currentTab: 1 };
  }

  render() {
    return (
      <div className = "rewards-wrapper reward_wid_sec">
        <Typography style={{ marginBottom: 20, fontFamily :"NotoSans-Bold" }}>SET REWARDS</Typography>
        <Paper square>
          <Tabs
            value={this.state.currentTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={this.handleChange}
          >
            <Tab value={1} label="Set Discount" />
            <Tab value={2} label="Set Loyalty" />
          </Tabs>
        </Paper>
        <Paper style={{ marginTop: 12, paddingBottom: 50 }} square>
          {this.state.currentTab === 1 ? (
            <Discount></Discount>
          ) : (
            <Loyalty></Loyalty>
          )}
        </Paper>
      </div>
    );
  }
}
