import React from "react";
import "./App.scss";
import { PersistGate } from "redux-persist/es/integration/react";
import { connect, Provider } from "react-redux";
import store, { history, persistor } from "./store/store";
import Routes from "./rootRouter";

export default function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <div
          style={
            {
              // minHeight: "100vh",
              // overflow: "hidden",
            }
          }
        >
          <div
            style={{
              minHeight: "calc(100vh - 64px)",
              marginTop: 30,
            }}
          >
            <Routes />
          </div>
        </div>
      </PersistGate>
    </Provider>
  );
}
