import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
const RedirectPaths = ["/login", "/create", "/home"];

const RouteMiddleWare = (props) => {
  const { token, component: Component, path } = props;
  if (RedirectPaths.includes(path) && !token) {
    return <Component {...props} />;
  } else {
    return <Redirect to={{ pathname: "/", state: { from: props.location } }} />;
  }
};

function RedirectRoute({ component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <RouteMiddleWare component={component} {...props} {...rest} />
      )}
    />
  );
}

export default connect(({ auth: { userDetails = {} } }) => {
  let { token } = userDetails;
  return { token };
})(RedirectRoute);
