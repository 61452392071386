import React from "react";
import { Button, FormControlLabel, Radio, Switch } from "@material-ui/core";
import { COLORS, FONTS } from "../../constants";
import DatePicker from "../../forms/datePicker";
import { TextInput } from "../../forms/textinput";

const transferWrapper = {
  boxSizing: "border-box",
};
const section_one = {
  fontSize: FONTS.size_medium,
  backgroundColor: COLORS.theme_light_blue,
  boxSizing: "border-box",
};
const sectionHeading = {
  display: "flex",
  justifyContent: "space-between",
};
const headingBlock = {
  display: "flex",
  alignItems: "flex-start",
  flex: 1,
};
const sectionOne_text = {
  marginLeft: "40px",
};
const initaiteTransfer_section = {
  fontSize: FONTS.size_medium,
  backgroundColor: COLORS.theme_light_blue,
  boxSizing: "border-box",
  marginTop: "15px",
};
const formContainer = {
  backgroundColor: COLORS.default_white,
};
const initiateBtn = {
  backgroundColor: COLORS.juma_theme_clr,
  color: COLORS.default_white,
  borderRadius: "2px",
  marginLeft: "40px",
  padding: "6px 15px",
};

export default class Transfer extends React.Component {
  keyPress = () => (e) => {
    if (e.key === "Enter") {
      this.props.transferMoney();
      e.preventDefault();
    }
  };

  render() {
    return (
      <div style={transferWrapper} className="transfer_main">
        <div style={section_one} className="eachTransfer_Section">
          <div style={sectionHeading}>
            <div style={headingBlock}>
              <div style={{ flex: "0 0 40px" }}>
                <img src="/images/calendar.svg" />
              </div>
              <p className="fontBold" style={{ margin: 0, marginTop: "2px" }}>
                End-of-Week Transfer to Linked Bank Account
              </p>
            </div>
            <div>
              <Switch
                checked="true"
                size="small"
                color="primary"
                name="checkedA"
                // inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </div>
          </div>
          <div style={sectionOne_text}>
            By enabling this, funds will be debited from your Jumna account and
            will be credited to your linked bank account on every Friday
          </div>
        </div>
        <div style={initaiteTransfer_section} className="eachTransfer_Section">
          <div style={headingBlock}>
            <div style={{ flex: "0 0 40px" }}>
              <img src="/images/initiate_transfer.svg" />
            </div>
            <p className="fontBold" style={{ margin: 0, marginTop: "2px" }}>
              Initiate Transfer
            </p>
          </div>
          <div className="transferFormWrapper">
            <div style={{ marginRight: "40px" }}>
              <div style={{ marginBottom: "30px" }}>
                <p>Linked Bank account</p>
                <p>XXXXXXXXXXXX89</p>
              </div>
              <div>
                <p>Routing Number</p>
                <p>XXXXXXXX91</p>
              </div>
            </div>
            <div style={formContainer} className="transferFormContainer">
              <div className="balance_radio">
                <FormControlLabel
                  style={{ marginRight: "60px" }}
                  value="transfer balance"
                  checked="true"
                  control={<Radio color="primary" />}
                  label="Transfer Current balance"
                />
                <FormControlLabel
                  value="Modify amount"
                  control={<Radio color="primary" />}
                  label="Modify Amount"
                />
              </div>
              <div style={{ width: "250px" }} className="datePicker">
                <DatePicker style={{ width: "100%" }} label="Select Date" />
              </div>
              <div className="amountInput edit-wrapper">
                <TextInput
                  style={{ minWidth: "250px" }}
                  label={"Enter amount"}
                  type="number"
                  onWheel={(event) => {
                    event.preventDefault();
                  }}
                  value={this.props.amount.value}
                  onChange={this.props.onChange}
                  isError={this.props.amount.isError}
                  errorMessage={this.props.amount.errorMessage}
                  onKeyPress={this.keyPress()}
                ></TextInput>
                <Button
                  onClick={this.props.transferMoney}
                  style={initiateBtn}
                  className="initiate_button"
                >
                  Initiate Transfer
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
