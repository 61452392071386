import { CALL_API } from "../api.middleware";
import { GET_CREDIT_NOTES, GET_CREDIT_NOTES_SUCCESS } from "../types/creditNotes";

export const getCreditNotes = () => async (dispatch) => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/merchant/get-credit-notes`,
        showLoader: true,
        method: "GET",
        types: [GET_CREDIT_NOTES, GET_CREDIT_NOTES_SUCCESS],
      },
    });
  } catch (error) {
    alert(`${error}`);
  }
};
