import React from "react";
import LeftPanel from "../forms/leftnav.js";
import Header from "../forms/textinput";
import Marque from "../views/marque";
import { COLORS, FONTS } from "../constants";
import { Button } from "@material-ui/core";

const highlightText = {
  fontSize: "30px",
  // fontSize : "2.2vw",
  color: COLORS.juma_theme_clr,
};
const sectionHeading = {
  fontFamily: "NotoSans-Bold",
  fontSize: FONTS.font_heading,
  margin: 0,
  marginBottom: "40px",
};
const sectionTextFont = {
  fontSize: FONTS.size_macro,
  // fontSize : "1.5vw",
  marginBottom: "35px",
  lineHeight: 1.6,
};
const howItWorks_section = {
  display: "flex",
  alignItems: "flex-start",
  marginTop: "15px",
  marginBottom: "50px",
};
const howItWorks_text_wrapper = {
  fontSize: FONTS.size_xtra,
  marginLeft: "20px",
};
const howItWorks_text = {
  margin: "5px 0",
};
const imgWrapper = {
  flex: "0 0 60px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "10px",
};
const blue_text = {
  color: COLORS.juma_theme_clr,
};
const section_four_points = {
  display: "flex",
  alignItems: "flex-start",
  marginBottom: "28px",
};
const points_text = {
  fontSize: FONTS.size_xtra,
  margin: "5px 0",
  marginLeft: "15px",
};
const section_FourHeading = {
  fontFamily: "NotoSans-Bold",
  fontSize: FONTS.size_macro,
  margin: 0,
  marginBottom: "40px",
};
const point_icon = {
  marginTop: "10px",
};

export default class Home extends React.Component {
  render() {
    return (
      <div style={{ marginTop: "65px" }} className="landingPage">
        <Header history={this.props.history} />
        <div className="banner_image">
          <div className="mobile-banner">
            <div className="bannerText-Wrapper">
              <p className="bannerHead bannerText fontBold">Your</p>
              <p className="bannerHead bannerText fontBold">Financial</p>
              <p className="bannerHead bannerText fontBold">Network</p>
              <p className="bannerText fontBold transText">
                No transaction fee for personal &amp; business accounts
              </p>
            </div>
            <div className="bannerButtons">
              <Button
                onClick={() => this.props.history.push("/create")}
                className="createBtn"
              >
                Create Account
              </Button>
              <Button
                onClick={() => this.props.history.push("/login")}
                className="signinBtn"
              >
                Business Sign-In
              </Button>
            </div>
          </div>
          <img
            className="webBanner"
            style={{ width: "100%" }}
            src="/images/jumna_banner.svg"
          />
          <img
            className="mobileBanner"
            style={{ width: "100%" }}
            src="/images/mobile-banner.svg"
          />
          <div className="marquee_wrapper">
            <p className="marquee_text fontBold">
              LINK YOUR BANK . LOAD CASH . GET CREDIT . PAY . IN-STORE . ONLINE
              . SEND MONEY . REQUEST PAYMENT . INSTANT . EASY . FREE . JOIN THE
              NEW WAY TO TRANSACT . GET THE JUMNA APP
            </p>
          </div>
        </div>
        {/* <div className="eachSection sectionOne">
          <div className="section_one_text">
            <p style={sectionHeading}>Jumna Advantage</p>
            <div className="mobileSectionOne">
              <img style={{ width: "100%" }} src="/images/section_one_image.svg" />
            </div>
            <p style={sectionTextFont}>You don’t have to take out a low rate personal loan to pay off credit card debt!
              <span style={highlightText}> We make the lowest rate available to you, straight at point-of-sale.</span>
            </p>
            <p style={sectionTextFont}>
              And as the merchant doesn’t pay out a chunk in transaction fees, they have more to reward you with.
            </p>
            <p style={sectionTextFont}>
              <span style={highlightText}>Get plugged in.</span>  Enjoy our user experience. You’ll forget you were ever tapping your phone awkwardly or carrying a card. Link your bank.
            </p>
            <p style={sectionTextFont}>
              Get connected. Enjoy direct rewards.
            </p>
          </div>
          <div className="section_one_image webSectionOne">
            <img style={{ width: "100%" }} src="/images/section_one_image.svg" />
          </div>
        </div>
        <div className="eachSection sectionTwo">
          <div className="section_two_image">
            <img style={{ width: "100%" }} src="/images/section_two_image.svg" />
          </div>
          <div className="section_two_text">
            <p style={sectionTextFont}>
              We have the necessary fraud protocols to ensure that merchants face no chargebacks.
            </p>
            <p style={sectionTextFont}>
              <span style={highlightText}>Jumna is the first network to unburden the merchant; </span>  we work hard to eliminate the middle man and get you customers.
            </p>
            <p style={sectionTextFont}>
              Use Jumna to receive payment 	&amp; to send invoices 	&amp; generate payment requests.
            </p>
          </div>
        </div>
        <div className="eachSection sectionThree">
          <div className="section_three_text">
            <p style={sectionHeading}>How it works? It’s simple.</p>
            <div style={howItWorks_section}>
              <div style={imgWrapper}>
                <img src="/images/mobile_icon.svg" />
              </div>
              <div style={howItWorks_text_wrapper}>
                <p style={{ ...howItWorks_text, ...blue_text }}>Get the Jumna App.</p>
                <p style={howItWorks_text}>For all your financial needs.</p>
              </div>
            </div>
            <div style={howItWorks_section}>
              <div style={imgWrapper}>
                <img src="/images/cart_icon.svg" />
              </div>
              <div style={howItWorks_text_wrapper}>
                <p style={howItWorks_text}>Shop in-store, in-app &amp; online.</p>
                <p style={howItWorks_text}>Send money, Request payment.</p>
              </div>
            </div>
            <div style={howItWorks_section}>
              <div style={imgWrapper}>
                <img src="/images/track_icon.svg" />
              </div>
              <div style={howItWorks_text_wrapper}>
                <p style={howItWorks_text}>We track where you are.</p>
                <p style={howItWorks_text}>You select amount and hit pay.</p>
                <p style={howItWorks_text}>We make an instant connection &amp; you are done. </p>
              </div>
            </div>
          </div>
          <div className="section_three_image">
            <img style={{ width: "100%" }} src="/images/howitworks_image.svg" />
          </div>
        </div>
        <div className="eachSection sectionFour">
          <div className="section_four_text">
            <p style={section_FourHeading}>If you are not a sole proprietor, create &amp; manage your account online.</p>
            <div style={section_four_points}>
              <img style={point_icon} src="/images/point_icon.svg" />
              <p style={points_text}>Activate store location, to accept payment in-store. Use a dedicated device or upload our software on to your point-of-sale terminal.</p>
            </div>
            <div style={section_four_points}>
              <img style={point_icon} src="/images/point_icon.svg" />
              <p style={points_text}>Set up a promotional campaign. Then leave it to us to get you customers.</p>
            </div>
            <div style={section_four_points}>
              <img style={point_icon} src="/images/point_icon.svg" />
              <p style={points_text}>Reward your customers for repeat spend. Set up a loyalty program.</p>
            </div>
          </div>
          <div className="section_four_image">
            <img style={{ width: "100%" }} src="/images/section_four_image.png" />
          </div>
        </div>
        <div className="footer_content">
          <div>
            <img style={{ width: "90px" }} src="/images/jumna_logo.svg" />
            <p style={{ margin: 0, fontSize: FONTS.size_small }}>&copy; Copyright 2020</p>
          </div>
          <div className="navList">
            <p className="cursor" style={{ fontSize: FONTS.size_medium }}>Terms &amp; Conditions</p>
            <p className="cursor" style={{ fontSize: FONTS.size_medium }}>Privacy Policy</p>
          </div>
        </div> */}
      </div>
    );
  }
}
