import React from "react";
import { connect } from "react-redux";
import { Email_regex } from "../constants";
import { onLogin } from "../store/actions/auth";
import Login_View from "../views/login";

class Login extends Login_View {
  onSubmit = async () => {
    const body = {
      email: this.state.username.value,
      password: this.state.password.value,
    };
    let key = this.setErrorMessages();
    if (!key) {
      let res = await this.props.onLogin(body);
      if (res) {
        this.props.history.push("/portal");
      }
    } else {
      return this.setState({
        [key]: {
          ...this.state[key],
          isError: true,
          errorMessage: `Please enter valid ${key}`,
        },
      });
    }
  };

  setErrorMessages = () => {
    let fetchedKey = null;
    Object.keys(this.state).some((key) => {
      if (
        this.state[key].value === "" ||
        (key == "username" && !Email_regex.test(this.state.username.value))
      ) {
        return (fetchedKey = key);
      }
    });
    return fetchedKey;
  };

  onInputChange = (key) => (e) => {
    this.setState({ [key]: { value: e.target.value } });
  };
}
export default connect(
  ({ auth }) => {
    return { userDetails: auth.userDetails };
  },
  { onLogin }
)(Login);
