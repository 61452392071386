import { LOGOUT_SUCCESS } from "../types/auth";
import {
  CREATE_DISCOUNT_SUCCESS,
  CREATE_LOYALTY_SUCCESS,
  DISCOUNT_ADD_SCREEN,
  DISCOUNT_DELETE_SUCCESS,
  DISCOUNT_EDIT_SUCCESS,
  DISCOUNT_LIST_SUCCESS,
  EDIT_LOYALTY_SUCCESS,
  LOYALTY_LIST_SUCCESS,
} from "../types/rewards";

const initState = {
  discounts: [],
  loyalty: [],
};

export default function rewardsReducer(state = initState, action) {
  switch (action.type) {
    case DISCOUNT_LIST_SUCCESS: {
      const { data = [] } = action.body;
      return { ...state, discounts: data };
    }
    case CREATE_DISCOUNT_SUCCESS: {
      const { data = {} } = action.body;
      return { ...state, discounts: [...state.discounts, data.discount] };
    }
    case DISCOUNT_EDIT_SUCCESS: {
      const { data = {} } = action.body;
      return {
        ...state,
        discounts: [
          ...state.discounts.filter(
            (item) => item.id !== (data.discount || {}).id
          ),
          data.discount,
        ],
      };
    }
    case LOGOUT_SUCCESS: {
      return initState;
    }
    case LOYALTY_LIST_SUCCESS: {
      const { data = [] } = action.body;
      return { ...state, loyalty: data };
    }

    case CREATE_LOYALTY_SUCCESS: {
      const { data = {} } = action.body;
      return { ...state, loyalty: [...state.loyalty, data.data || {}] };
    }
    case EDIT_LOYALTY_SUCCESS: {
      const { data = {} } = action.body;
      return {
        ...state,
        loyalty: [
          ...state.loyalty.filter((item) => item.id !== (data.data || {}).id),
          data.data,
        ],
      };
    }
    default:
      return state;
  }
}
