export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT = "LOGOUT";

export const SHOW_TOAST = "SHOW_TOAST";
export const STOP_TOAST = "STOP_TOAST";

export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const CREATE_MERCHANT = "CREATE_MERCHANT";
export const CREATE_MERCHANT_SUCCESS = "CREATE_MERCHANT_SUCCESS";

export const GET_BUSSINESS_CATEGORY = "GET_BUSSINESS_CATEGORY";
export const GET_BUSSINESS_CATEGORY_SUCCESS = "GET_BUSSINESS_CATEGORY_SUCCESS";

export const SIGN_UP = "SIGN_UP";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";

export const VALIDATE_CODE = "VALIDATE_CODE";
export const VALIDATE_CODE_SUCCESS = "VALIDATE_CODE_SUCCESS";

export const VALIDATE_EXPERIAN = "VALIDATE_EXPERIAN";
export const VALIDATE_EXPERIAN_SUCCESS = "VALIDATE_EXPERIAN_SUCCESS";
