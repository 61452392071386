import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getCreditNotes } from "../../store/actions/creditNotes";

const headers = [
  "Store",
  "Transaction Id",
  "Aisle Name",
  "Amount",
  'Discount',
  'Tip',
  "Consumer Id",
  "is Proccessed",
  "Updated At",
];

function CreditNotes(props) {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      let { data = [] } = await props.getCreditNotes();
      setData(data);
    };
    fetch();
  }, []);

  const _getTableBody = (row = {}) => {
    const {
      aisle_info = {},
      amount = 0,
      updatedAt,
      is_processed,
      payment_info = {},
      store_info = {},
      consumer_id = "",
    } = row;
    const { device_name } = aisle_info;
    const { transactionId,discount = 0,tip = 0 } = payment_info;
    const { name } = store_info;
    return (
      <TableRow key={transactionId}>
        <TableCell component="th" scope="row">
          {name}
        </TableCell>
        <TableCell align="right">{transactionId}</TableCell>
        <TableCell align="right">{device_name}</TableCell>
        <TableCell align="right">{amount}</TableCell>
        <TableCell align="right">{discount}</TableCell>
        <TableCell align="right">{tip}</TableCell>
        <TableCell align="right">{consumer_id}</TableCell>
        <TableCell align="right">{`${is_processed}`}</TableCell>
        <TableCell align="right">{updatedAt}</TableCell>
      </TableRow>
    );
  };

  return (
    <div style={{ margin: 50 }}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell align={index === 0 ? "" : "right"}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{data.map((row) => _getTableBody(row))}</TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default connect(null, { getCreditNotes })(CreditNotes);
