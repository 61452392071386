// styles --->

// export const BUTTON_GREEN_COLOR = "#09f4c7";
export const COLORS = {
  BUTTON_GREEN_COLOR: "#09f4c7",
  default_white: "#fff",
  juma_theme_clr: "#1A7CFA",
  default_black: "#000",
  table_head: "#F4F9FF",
  theme_light_blue: "#F4F9FF",
};
export const FONTS = {
  size_max: "25px",
  size_macro: "22px",
  size_mega: "20px",
  size_xtra: "18px",
  size_large: "16px",
  size_normal: "15px",
  size_medium: "14px",
  size_small: "13px",
  size_minimum: "12px",
  size_mini: "11px",
  size_micro: "10px",
  size_tiny: "8px",
  font_heading: "32px",
};
export const SIDENAV_ITEMS = [
  {
    activeImage: "/images/balance_active.svg",
    inactive: "/images/balance_inactive.svg",
    label: "Balance",
    keyRoute: "/balance",
  },
  {
    activeImage: "/images/transaction_record_active.svg",
    inactive: "/images/transaction_record_inactive.svg",
    label: "Credit Notes",
    keyRoute: "/credits",
  },
  {
    activeImage: "/images/set_rewards_active.svg",
    inactive: "/images/set_rewards_inactive.svg",
    label: "Set Rewards",
    keyRoute: "/set-rewards",
  },
  {
    activeImage: "/images/activate_store_location_active.svg",
    inactive: "/images/active_store_location_inactive.svg",
    label: "Activate Store Location",
    keyRoute: "/balance",
  },
  {
    activeImage: "/images/admin_active.svg",
    inactive: "/images/admin_inactive.svg",
    label: "Admin",
    keyRoute: "/balance",
  },
  {
    activeImage: "/images/customer_support_active.svg",
    inactive: "/images/customer_support_inactive.svg",
    label: "Customer Support",
    keyRoute: "/balance",
  },
  {
    inactive: "/images/logout_inactive.svg",
    label: "Logout",
    keyRoute: "/balance",
  },
];

export const Email_regex = /^\w+([\.-]?\w+)+(\+\d)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const IMAGE_LOGO_TYPES = ".PNG, .JPEG, .JPG";
