import { Card, Typography } from "@material-ui/core";
import React from "react";
import Header, { Buttton, TextInput } from "../../forms/textinput";
import DatePicker from "../../forms/datePicker";
import { eachInput } from "../rewards/rewardStyle";

const formFields = [
  { label: "First Name", key: "firstName" },
  { label: "Last Name", key: "lastName" },
  { label: "Mobile", key: "mobile" },
  { label: "Email", key: "email" },
  { label: "Street Address", key: "address" },
  { label: "Suite/Apt", key: "suite" },
  { label: "City", key: "city" },
  { label: "State", key: "state" },
  { label: "Zip code", key: "zipCode" },
  { label: "Country", key: "country" },
  // { label: "Citizeship", key: "citizenship" },
  // { label: "State ID or Driving license", key: "idProofNo" },
  { label: "DOB", key: "dob" },
  { label: "SSN", key: "ssn" },
];

const getInitState = () => {
  return formFields.reduce((prev, curr) => {
    return { ...prev, [curr.key]: { value: "" } };
  }, {});
};

const submitBtn = {
  display: "flex",
  justifyContent: "center",
  marginTop: "40px",
  marginBottom: "15px",
};

export default class SignUp extends React.Component {
  constructor(props) {
    super(props);
    const formState = getInitState();
    this.state = { ...formState, loadComponent: true, paramsData: {} };
  }

  keyPress = () => (e) => {
    if (e.key === "Enter") {
      this.signUp();
      e.preventDefault();
    }
  };

  render() {
    return (
      <div className="signupWrapper signup_wid_sec">
        {/* <Header history={this.props.history} /> */}
        {this.state.loadComponent && (
          <Card className="merchent_wid_sec">
            <p className="textCenter fontBold" style={{ fontSize: 18 }}>
              Merchant Sign Up
            </p>
            <p varient="caption" className="textCenter">
              Thanks, We've ascertained you are an authentic business. Please
              enter the details of authorised person/s
            </p>
            <div className="merchent_signup_ip">
              {formFields.map(({ key, label }) => {
                if (key === "dob") {
                  return (
                    <div
                      key={key}
                      style={{
                        width: "40%",
                        margin: "auto",
                        marginBottom: 0,
                        marginTop: 0,
                      }}
                      className="dob_input"
                    >
                      <DatePicker
                        className="date-picker"
                        handleDateChange={this.handleDateChange("dob")}
                        selectedDate={this.state.dob.value}
                        label="DOB"
                        errorMessage={this.state.dob.errorMessage}
                        isError={this.state.dob.isError}
                        maxDate={new Date()}
                        disableToolbar={false}
                      />
                    </div>
                  );
                } else {
                  return (
                    <TextInput
                      key={key}
                      style={{
                        width: "40%",
                        margin: "auto",
                        marginTop: 15,
                        marginBottom: 15,
                      }}
                      onChange={this.onInputChange(`${key}`)}
                      value={this.state[key].value}
                      label={label}
                      errorMessage={this.state[key].errorMessage}
                      isError={this.state[key].isError}
                      onKeyPress={this.keyPress()}
                    ></TextInput>
                  );
                }
              })}
            </div>
            <div style={submitBtn}>
              <Buttton onClick={this.signUp} label={"SUBMIT"}></Buttton>
            </div>
          </Card>
        )}
      </div>
    );
  }
}
