export const CREATE_DISCOUNT = "CREATE_DISCOUNT";
export const CREATE_DISCOUNT_SUCCESS = "CREATE_DISCOUNT_SUCCESS";

export const DISCOUNT_ADD_SCREEN = "DISCOUNT_ADD_SCREEN";
export const DISCOUNTS_VIEW_SCREEN = "DISCOUNTS_VIEW_SCREEN";
export const DISCOUNT_EDIT_SCREEN = "DISCOUNT_EDIT_SCREEN";

export const FILE_UPLOAD = "FILE_UPLOAD";
export const FILE_UPLOAD_SUCCESS = "FILE_UPLOAD_SUCCESS";

export const DISCOUNT_LIST = "DISCOUNT_LIST";
export const DISCOUNT_LIST_SUCCESS = "DISCOUNT_LIST_SUCCESS";

export const DISCOUNT_EDIT = "DISCOUNT_EDIT";
export const DISCOUNT_EDIT_SUCCESS = "DISCOUNT_EDIT_SUCCESS";

export const DISCOUNT_DELETE = "DISCOUNT_DELETE";
export const DISCOUNT_DELETE_SUCCESS = "DISCOUNT_DELETE_SUCCESS";

export const CREATE_LOYALTY = "CREATE_LOYALTY";
export const CREATE_LOYALTY_SUCCESS = "CREATE_LOYALTY_SUCCESS";

export const EDIT_LOYALTY = "EDIT_LOYALTY";
export const EDIT_LOYALTY_SUCCESS = "EDIT_LOYALTY_SUCCESS";

export const DELETE_LOYALTY = "DELETE_LOYALTY";
export const DELETE_LOYALTY_SUCCESS = "DELETE_LOYALTY_SUCCESS";

export const LOYALTY_LIST = "LOYALTY_LIST";
export const LOYALTY_LIST_SUCCESS = "LOYALTY_LIST_SUCCESS";

export const LOYALTY_ADD_SCREEN = "LOYALTY_ADD_SCREEN";
export const LOYALTY_VIEW_SCREEN = "LOYALTY_VIEW_SCREEN";
export const LOYALTY_EDIT_SCREEN = "DISCOUNT_EDIT_SCREEN";
