import { Card } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import OTP from "../../forms/otp";
import { validateCode } from "../../store/actions/auth";

const otpCard = {
  width: "65%",
  marginTop: "40px",
  padding: "20px 50px",
};

class EmailOTP extends React.Component {
  handleSubmit = async (otp) => {
    let res =
      (await this.props.validateCode({ otp }, this.props.otpToken)) || {};
    if (!!res.success) {
      this.props.history.push("/");
    }
  };

  // componentDidMount() {
  //   if (!this.props.otpToken) {
  //     this.props.history.push("/");
  //     // -> Checking Authenticated one way flow using store
  //   }
  // }

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card style={otpCard}>
          <OTP handleSubmit={this.handleSubmit} />
        </Card>
      </div>
    );
  }
}

export default connect(
  ({ loader }) => {
    return { otpToken: loader.otpToken };
  },
  { validateCode }
)(EmailOTP);
