import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import React from "react";
import {
  DISCOUNTS_VIEW_SCREEN,
  DISCOUNT_EDIT_SCREEN,
  DISCOUNT_EDIT_SUCCESS,
} from "../../store/types/rewards";
import { SelectInput, TextInput } from "../../forms/textinput";
import DatePicker from "../../forms/datePicker";
import FileUpload from "../../forms/fileUpload";
import {
  rewardTabOptions,
  addBtn,
  listwrapper,
  discountText,
  btnActions,
  actionBtn,
  addDiscount,
  contentWrapper,
  inputWrapper,
  eachInput,
  setBtn,
} from "./rewardStyle";
import moment from "moment";
export default class Discount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.getInitState(),
    };
  }

  getInitState = () => {
    return {
      filter: "current",
      action: DISCOUNTS_VIEW_SCREEN,
      discount: { value: "" },
      description: { value: "Sale" },
      fromDate: { value: new Date() },
      toDate: { value: new Date() },
      // fromDate: { value: new Date(new Date().getTime() - 1000 * 60 * 60 * 24) },
      // toDate: { value: new Date(new Date().getTime() - 1000 * 60 * 60 * 24) },
      displayLogoId: { value: "" },
      spotlightImageId: { value: "" },
      editDiscountId: null,
    };
  };

  render() {
    const filteredDiscounts = this.filterDiscounts(this.props.discounts);
    
    return (
      <div class="discount_main_sec" style={{ paddingRight: 30 }}>
        {this.state.action === DISCOUNTS_VIEW_SCREEN ? (
          <div className="rewardTab_options" style={rewardTabOptions}>
            <div
              // style={{
              //   display: "flex",
              //   flexDirection: "row",
              //   justifyContent: "space-between",
              // }}
              class="reward_option_wid"
            >
              <RadioGroup
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
                value={this.state.filter}
                onChange={this.onChange}
              >
                <FormControlLabel
                  value="current"
                  control={<Radio size="small" color="primary" />}
                  label="Current"
                />
                <FormControlLabel
                  value="upcoming"
                  control={<Radio size="small" color="primary" />}
                  label="Upcoming"
                />
                <FormControlLabel
                  value="archived"
                  control={<Radio size="small" color="primary" />}
                  label="Archived"
                  // class="archived_label"
                />
              </RadioGroup>
              <Button onClick={this.onAdd} style={addBtn}>
                + Add New
              </Button>
            </div>
            <div style={listwrapper} className="listItemwrapper">
              {!!(filteredDiscounts[this.state.filter] || []).length ? (
                (filteredDiscounts[this.state.filter] || []).map(
                  (item, index) => (
                    <div key={index} className="discount_Item">
                      {" "}
                      {/* list of items to be looped */}
                      <div
                        style={{
                          backgroundImage: `url(${item.displayLogoId})`,
                        }}
                        className="discount_imagewrapper"
                      >
                        {/* <img style={discount_image} src={item.displayLogoId} /> */}
                      </div>
                      <p style={discountText} className="fontBold">
                        {`${item.discount}% ${item.discription}`}
                      </p>
                      {/* {this.state.filter === "upcoming" && ( */}
                      <div style={btnActions} className="actionBtn">
                        {" "}
                        {/* show/hide this div based on tab */}
                        <Button onClick={this.onEdit(item)} style={actionBtn}>
                          Edit
                        </Button>
                        <Button
                          onClick={this.onDelete(item.id)}
                          style={actionBtn}
                        >
                          Delete
                        </Button>
                      </div>
                      {/* )} */}
                    </div>
                  )
                )
              ) : (
                <Typography class="no_discount_available">
                  {this.props.showLoader
                    ? "Loading..."
                    : "No Discounts Available"}
                </Typography>
              )}
            </div>
          </div>
        ) : (
          <DiscountAdd
            handleDateChange={this.handleDayClick}
            filter={this.state.filter}
            onInputChange={this.onInputChange}
            onFileUpload={this.onFileUpload}
            onSetDiscount={this.onSetDiscount}
            goBack={() =>
              this.setState({
                action: DISCOUNTS_VIEW_SCREEN,
                ...this.getInitState(),
              })
            }
            {...this.state}
          />
        )}
      </div>
    );
  }
}

export function DiscountAdd(props) {
  const {
    discount = {},
    addTitle = {},
    description = {},
    fromDate = {},
    toDate = {},
    displayLogoId = {},
    action,
    spotlightImageId,
    filter,
  } = props;
  return (
    <div style={addDiscount} className="add_discount">
      <p className="fontBold" style={{ display: "flex" }}>
        {" "}
        <img
          onClick={props.goBack}
          src="/images/back.svg"
          style={{ marginRight: "15px", marginTop: "2px", cursor: "pointer" }}
        />{" "}
        {action === DISCOUNT_EDIT_SCREEN
          ? "Edit Discount"
          : " Set New Discount"}
      </p>
      <div style={contentWrapper}>
        <div style={inputWrapper} class="new_discount_main">
          <div style={eachInput} class="new_discount_ip">
            <TextInput
              style={{ width: "100%" }}
              onChange={props.onInputChange(`discount`)}
              value={discount.value}
              label={"% Discount"}
              errorMessage={discount.errorMessage}
              isError={discount.isError}
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              // type="number"
            ></TextInput>
          </div>

          <div className="select_desc">
            <SelectInput
              style={{ width: "100%" }}
              onChange={props.onInputChange(`description`)}
              value={description.value}
              label={"Select Description"}
              options={[
                { label: "Sale", value: "Sale" },
                { label: "Off", value: "Off" },
                { label: "Discount", value: "Discount" },
              ]}
              isError={description.isError}
              errorMessage={description.errorMessage}
            ></SelectInput>
          </div>
        </div>

        <div style={inputWrapper} class="new_discount_main">
          <div style={eachInput} class="new_discount_ip">
            <DatePicker
              handleDateChange={props.handleDateChange(
                "fromDate",
                "Valid From Date"
              )}
              selectedDate={fromDate.value}
              minDate={new Date()}
              //minDate={new Date(new Date().getTime() + 1000 * 60 * 60 * 24)}
              label="Valid From Date"
              errorMessage={fromDate.errorMessage}
              isError={fromDate.isError}
            />
          </div>
          <div style={eachInput} class="new_discount_ip">
            <DatePicker
              handleDateChange={props.handleDateChange(
                "toDate",
                "Valid To Date"
              )}
              selectedDate={toDate.value}
              minDate={new Date()}
              //minDate={new Date(fromDate.value)}
              label="Valid To Date"
              errorMessage={toDate.errorMessage}
              isError={toDate.isError}
            />
          </div>
        </div>
        {/* <div style={inputWrapper}>
          <TextInput
            style={{ width: "100%" }}
            onChange={props.onInputChange(`description`)}
            value={description.value}
            label={"Description"}
            fullWidth={true}
          ></TextInput>
        </div> */}
        <div style={{ width: "60%", marginTop: 20 }} className="uploadfile">
          <FileUpload
            onChange={props.onFileUpload("displayLogoId")}
            label={"Upload Display Logo/Campaign"}
            isError={displayLogoId.isError}
            isFileAdded={!!displayLogoId.value}
            imgSrc={displayLogoId.value}
          />
          <Typography style={{ color: "red" }} variant="caption">
            {displayLogoId.errorMessage}
          </Typography>
        </div>

        <div style={{ width: "60%", marginTop: 20 }} className="uploadfile">
          <FileUpload
            onChange={props.onFileUpload(`spotlightImageId`)}
            label={"Upload Spotlight Logo/Campaign"}
            isFileAdded={!!spotlightImageId.value}
            imgSrc={spotlightImageId.value}
          />
        </div>
        <div style={{ marginTop: 30 }}>
          <Button
            onClick={props.onSetDiscount(action === DISCOUNT_EDIT_SCREEN)}
            color="Primary"
            style={setBtn}
          >
            {action === DISCOUNT_EDIT_SCREEN ? "EDIT" : "SET"}
          </Button>
        </div>
      </div>
    </div>
  );
}
