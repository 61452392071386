import React from "react";
import MyDashboardRoutes from "../controllers/dashboardFolder";
import LeftPanel from "../forms/leftnav";
import Header from "../forms/textinput";

const dashboardContainer = {
  width: "100%",
  margin: "auto",
  backgroundColor: "#F4F4F4",
  position: "fixed",
  bottom: 0,
  top: 0,
  overflow: "scroll",
};

export default class Dashboard extends React.Component {
  render() {
    const { pathname } = this.props.location;
    return (
      <div style={dashboardContainer}>
        <LeftPanel {...this.props}></LeftPanel>
        <div className="dashboardMain">
          {pathname === "/login" ? (
            <React.Fragment></React.Fragment>
          ) : (
            <Header {...this.props} />
          )}
          <MyDashboardRoutes {...this.props} />
        </div>
      </div>
    );
  }
}
