import {
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  SIGN_UP_SUCCESS,
  VALIDATE_CODE_SUCCESS,
} from "../types/auth";

const initState = {
  userDetails: {},
};

export default function auth(state = initState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
    case VALIDATE_CODE_SUCCESS: {
      const { body = {} } = action;
      return {
        ...state,
        userDetails: body.data,
      };
    }
    case LOGOUT_SUCCESS: {
      return initState;
    }
    default:
      return state;
  }
}
