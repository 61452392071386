export const getQueryParams = (search = " ") => {
  return (
    search
      .slice(1)
      .split(["&"])
      .map((item) => {
        let arr = item.split("=");
        return arr[1];
      }) || []
  );
};

export function validateFields(fields = {}, callBack) {
  let error = false;
  for (let key in fields) {
    if (!fields[key]) {
      error = true;
      callBack(key);
    }
  }
  return error;
}
