import { Typography } from "@material-ui/core";
import { IMAGE_LOGO_TYPES } from "../constants";

export default function FileUpload({
  label = "",
  isError = false,
  isFileAdded = false,
  imgSrc = "",
  ...fileProps
}) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <label>{label}</label>
      <input
        style={{
          border: isError && "1px dashed red",
          border: isFileAdded && "1px dashed green",
        }}
        {...fileProps}
        type="file"
        name={label}
        id={label}
        accept={IMAGE_LOGO_TYPES}
      />
      {!!imgSrc && (
        <div
          style={{
            border: "1px dashed gray",
            marginTop: 15,
            width: 120,
            height: 120,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{
              width: "95%",
            }}
            src={imgSrc}
          />
        </div>
      )}
    </div>
  );
}
