import { CALL_API } from "../api.middleware";
import {
  GET_ACCOUNT_BALANCE,
  GET_ACCOUNT_BALANCE_SUCCESS,
  GET_TIP_BALANCE,
  GET_TIP_BALANCE_SUCCESS,
  TRANSFER_FUNDS,
  TRANSFER_FUNDS_SUCCESS,
} from "../types/balance";

export const getAccountBalance = () => async (dispatch) => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/merchant/get_account_balance`,
        showLoader: true,
        method: "GET",
        types: [GET_ACCOUNT_BALANCE, GET_ACCOUNT_BALANCE_SUCCESS],
      },
    });
  } catch (error) {
    alert(`${error}`);
  }
};

export const transferBalance = (body) => async (dispatch) => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/merchant/credit_to_bank`,
        showLoader: true,
        showToast: true,
        toastMessage: "Transfered Successfully.",
        method: "POST",
        types: [TRANSFER_FUNDS, TRANSFER_FUNDS_SUCCESS],
        body,
      },
    });
  } catch (error) {
    alert(`${error}`);
  }
};

export const getTipBalance = () => async (dispatch) => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/merchant/get_tip_balance`,
        showLoader: true,
        // showToast: true,
        toastMessage: "",
        method: "GET",
        types: [GET_TIP_BALANCE, GET_TIP_BALANCE_SUCCESS],
      },
    });
  } catch (error) {
    alert(`${error}`);
  }
};
