import { connect } from "react-redux";
import {
  createLoyalty,
  deleteLoyatly,
  editLoyalty,
  loyaltyList,
} from "../../store/actions/rewards";
import {
  LOYALTY_ADD_SCREEN,
  LOYALTY_EDIT_SCREEN,
  LOYALTY_VIEW_SCREEN,
} from "../../store/types/rewards";
import Loyalty_View from "../../views/rewards/loyaty";

class Loyalty extends Loyalty_View {
  async componentDidMount() {
    await this.props.loyaltyList();
  }

  onAdd = () => {
    this.setState({ action: LOYALTY_ADD_SCREEN });
  };

  onInputChange = (key) => (e) => {
    if (key === "reward" && Number(e.target.value) > 100) {
      this.setState({
        reward: {
          ...this.state.reward,
          isError: true,
          errorMessage: "Reward spend must be in between 1% to 100 %",
        },
      });
      return;
    }
    this.setState({
      [key]: { value: e.target.value },
    });
  };

  onCreateLoyalty = (isEditCall) => async () => {
    const manditoryPayload = {
      spend: this.state.spend.value,
      // days: this.state.days.value,
      reward: this.state.reward.value,
    };
    const body = {
      spend: Number(this.state.spend.value),
      // days: Number(this.state.days.value),
      reward: Number(this.state.reward.value),
      toDate: this.state.toDate.value,
      fromDate: this.state.fromDate.value,
    };
    if (
      Object.keys(manditoryPayload).every(
        (key) => Number(manditoryPayload[key]) > 0
      )
    ) {
      let res = isEditCall
        ? await this.props.editLoyalty(this.state.editLoyaltyId, body)
        : await this.props.createLoyalty(body);
      if (!!(res || {}).success) {
        this.setState({ ...this.getInitState() });
      }
    } else {
      Object.keys(manditoryPayload).forEach((key) => {
        if (!manditoryPayload[key] || Number(manditoryPayload[key]) <= 0) {
          this.setState({
            [key]: {
              ...this.state[key],
              isError: true,
              errorMessage: !manditoryPayload[key]
                ? `${key} is required`
                : `${key} should be greater than 0`,
            },
          });
        }
      });
    }
  };

  handleDayClick = (key, label = "") => (date) => {
    if (isNaN(Date.parse(date))) {
      this.setState({
        [key]: {
          value: date,
          isError: true,
          errorMessage: `${label} should be in a valid format`,
        },
      });
      return;
    }
    this.setState({
      [key]: { value: date },
    });
  };

  onDeleteLoyalty = (id) => async () => {
    await this.props.deleteLoyatly(id);
    await this.props.loyaltyList();
  };

  onEdit = (item) => () => {
    const { spend, reward, toDate, fromDate, id } = item;
    this.setState({
      spend: { value: spend },
      // days: { value: days },
      reward: { value: reward },
      toDate: { value: toDate},
      fromDate: { value: fromDate } ,
      action: LOYALTY_EDIT_SCREEN,
      editLoyaltyId: id,
    });
  };
}

export default connect(
  ({ rewardsReducer }) => {
    const { loyalty } = rewardsReducer;
    return { loyalty };
  },
  { loyaltyList, createLoyalty, deleteLoyatly, editLoyalty }
)(Loyalty);
