import React from "react";
import { Typography, TextField, Button } from "@material-ui/core";
import "../App.scss";
import { Buttton as PrimaryButton } from "./textinput";
import { COLORS, FONTS } from "../constants"

const otpHeadText = {
  textAlign: "center",
  fontSize: FONTS.size_xtra,
  fontWeight: "bold",
}
const otpInput = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}
const inputWrapper = {
  textAlign : "center", 
  fontSize : FONTS.size_medium, 
  marginTop : "40px"
}
export default class OTP extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      otpArray: ["", "", "", "", "", ""],
      index: 0,
    };
  }

  componentDidMount() {
    window.addEventListener("keydown", this.handleKeyDown);
  }

  handleOtpChange = (index, value, key) => {
    let { otpArray } = this.state;
    if (!/^[\d ]*$/.test(value)) return;
    if ((value && !otpArray[index].length) || !value) {
      otpArray[index] = value;
      this.setState({ otpArray, index });
      if (index < 5 && value) {
        const ref = this[`otpRef${index + 1}`];
        ref.focus();
      }
    }
  };

  handleKeyDown = (event) => {
    let { otpArray, index } = this.state;
    if (event.key == "Backspace") {
      if (!otpArray[index]) {
        if (!(index - 1 < 0)) {
          otpArray[index - 1] = "";
          this.setState({ otpArray, index: index - 1 });
          const ref = this[`otpRef${index - 1}`];
          ref.focus();
        }
      }
    }
  };

  handleSubmit = () => {
    try {
      const { otpArray } = this.state;
      const otp = otpArray.join("");
      if (otp.length != 6) {
        throw new Error("Not a valid otp");
      }
      this.props.handleSubmit(otp);
    } catch (error) {
      alert(error.message);
    }
  };

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyDown);
  }

  render() {
    return (
      <div>
        <p style={otpHeadText}>{"Verify your Email Account"}</p>
        <div style={{ textAlign: "center", marginTop: 40, marginBottom: 10 }}>
          <img src="/images/mail.svg" />
        </div>
        <div style={inputWrapper}>
          <p>6-digit OTP has been sent to your registered email id</p>
          <div style={otpInput} className="otp-input">
            {this.state.otpArray.map((ele, index) => (
              <TextField
                size="medium"
                onFocus={() => this.setState({ index })}
                key={index}
                type={"password"}
                inputRef={(ref) => (this[`otpRef${index}`] = ref)}
                value={ele}
                margin="normal"
                variant="standard"
                style={{
                  width: 30,
                  marginRight: 5,
                  marginLeft: 5,
                  borderBottom: 1,
                  textAlign: "center"
                }}
                onChange={(event) =>
                  this.handleOtpChange(index, event.target.value.trim())
                }
              />
            ))}
          </div>
        </div>
        <div style={{display : "flex", justifyContent : "center"}}>
          <PrimaryButton
            style={{
              marginTop: 10,
              backgroundColor: COLORS.juma_theme_clr,
              marginTop: 50,
              width : 200
            }}
            onClick={this.handleSubmit}
            label={this.props.name || "SEND"}
          //   fullWidth={true}
          ></PrimaryButton>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="caption">Didn't receive OTP?</Typography>
          <Button
            style={{
              color: "#b72325",
            }}
            onClick={this.props.sendOtpAgain}
          >
            RESEND
          </Button>
        </div>
      </div>
    );
  }
}
