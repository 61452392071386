import { CALL_API } from "../api.middleware";
import {
  CREATE_DISCOUNT,
  CREATE_DISCOUNT_SUCCESS,
  CREATE_LOYALTY,
  CREATE_LOYALTY_SUCCESS,
  DELETE_LOYALTY,
  DELETE_LOYALTY_SUCCESS,
  DISCOUNT_DELETE,
  DISCOUNT_DELETE_SUCCESS,
  DISCOUNT_EDIT,
  DISCOUNT_EDIT_SUCCESS,
  DISCOUNT_LIST,
  DISCOUNT_LIST_SUCCESS,
  EDIT_LOYALTY,
  EDIT_LOYALTY_SUCCESS,
  FILE_UPLOAD,
  FILE_UPLOAD_SUCCESS,
  LOYALTY_LIST,
  LOYALTY_LIST_SUCCESS,
} from "../types/rewards";

export const createDiscount = (body) => async (dispatch) => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/discount/create`,
        showLoader: true,
        method: "POST",
        types: [CREATE_DISCOUNT, CREATE_DISCOUNT_SUCCESS],
        body,
      },
    });
  } catch (error) {
    alert(`${error}`);
  }
};

export const fileUpload = (file) => async (dispatch) => {
  let formData = new FormData();
  formData.append(`file`, file);
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/upload`,
        showLoader: true,
        method: "POST",
        types: [FILE_UPLOAD, FILE_UPLOAD_SUCCESS],
        body: formData,
        isForm: true,
      },
    });
  } catch (error) {
    alert(`${error}`);
  }
};

export const getDiscountList = () => async (dispatch) => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/discount/list`,
        showLoader: true,
        method: "GET",
        types: [DISCOUNT_LIST, DISCOUNT_LIST_SUCCESS],
      },
    });
  } catch (error) {
    alert(`${error}`);
  }
};

export const editDiscount = (id, body) => async (dispatch) => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/discount/edit/${id}`,
        showLoader: true,
        method: "POST",
        types: [DISCOUNT_EDIT, DISCOUNT_EDIT_SUCCESS],
        body,
      },
    });
  } catch (error) {
    alert(`${error}`);
  }
};

export const deleteDiscount = (id) => async (dispatch) => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/discount/delete/${id}`,
        showLoader: true,
        method: "DELETE",
        types: [DISCOUNT_DELETE, DISCOUNT_DELETE_SUCCESS],
      },
    });
  } catch (error) {
    alert(`${error}`);
  }
};

export const editLoyalty = (id, body) => async (dispatch) => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/loyalty/edit/${id}`,
        showLoader: true,
        method: "POST",
        types: [EDIT_LOYALTY, EDIT_LOYALTY_SUCCESS],
        body,
      },
    });
  } catch (error) {
    alert(`${error}`);
  }
};

export const deleteLoyatly = (id) => async (dispatch) => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/loyalty/delete/${id}`,
        showLoader: true,
        method: "DELETE",
        types: [DELETE_LOYALTY, DELETE_LOYALTY_SUCCESS],
      },
    });
  } catch (error) {
    alert(`${error}`);
  }
};

export const createLoyalty = (body) => async (dispatch) => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/loyalty/create`,
        showLoader: true,
        method: "POST",
        types: [CREATE_LOYALTY, CREATE_LOYALTY_SUCCESS],
        body,
      },
    });
  } catch (error) {
    alert(`${error}`);
  }
};

export const loyaltyList = () => async (dispatch) => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/loyalty/list`,
        showLoader: true,
        method: "GET",
        types: [LOYALTY_LIST, LOYALTY_LIST_SUCCESS],
      },
    });
  } catch (error) {
    alert(`${error}`);
  }
};
