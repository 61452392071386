import { Typography, Button } from "@material-ui/core";
import React from "react";
import { TextInput } from "../forms/textinput";
import { COLORS } from "../constants";
import { FONTS } from "../constants";

export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = { username: { value: "" }, password: { value: "" } };
  }

  keyPress = () => (e) => {
    if (e.key === "Enter") {
      this.onSubmit();
      e.preventDefault();
    }
  };

  goToSignIn = () => {
    this.props.history.push("/create");
  };

  render() {
    const formContainer = {
      backgroundColor: COLORS.default_white,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      boxSizing: "border-box",
      width: "450px",
      margin: "auto",
      padding: "30px",
      boxShadow: "0 0 6px #ccc",
    };
    const inputWrapper = {
      width: "100%",
      // maxWidth: "350px",
      margin: "15px 0",
    };
    const sbtBtn = {
      backgroundColor: COLORS.juma_theme_clr,
      width: "180px",
      color: COLORS.default_white,
      borderRadius: 0,
      fontFamily: "NotoSans-Bold",
    };
    const forgotPwd = {
      float: "right",
      fontSize: FONTS.size_minimum,
      margin: 0,
      color: COLORS.juma_theme_clr,
      marginTop: "4px",
      cursor: "pointer",
    };
    const loginContainer = {
      width: "100%",
      alignItems: "center",
      position: "fixed",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      background: "#F0F5FD",
      display: "flex",
    };
    return (
      // style={{ width: "100%", alignItems: "center",position:"fixed",top:"50%",left:"50%",transform:"translate(-50%,-50%)" }}
      <div style={loginContainer}>
        <div style={formContainer}>
          <img src="/images/jumna_logo.svg" style={{ width: "65px" }} />
          <Typography
            style={{
              fontFamily: "NotoSans-Bold",
              marginTop: "10px",
              fontSize: FONTS.size_xtra,
            }}
          >
            Sign in
          </Typography>
          <div style={inputWrapper}>
            <TextInput
              style={{ width: "100%" }}
              onChange={this.onInputChange(`username`)}
              value={this.state.username.value}
              label={"Username"}
              isError={this.state.username.isError}
              errorMessage={this.state.username.errorMessage}
            ></TextInput>
          </div>
          <div style={inputWrapper}>
            <TextInput
              style={{ width: "100%" }}
              onChange={this.onInputChange(`password`)}
              value={this.state.password.value}
              isError={this.state.password.isError}
              errorMessage={this.state.password.errorMessage}
              label={"Password"}
              type="password"
              onKeyPress={this.keyPress()}
            ></TextInput>
            <p style={forgotPwd}>Forgot Password?</p>
          </div>
          <div style={{ marginTop: 30, width: "100%", textAlign: "right" }}>
            <Button
              style={sbtBtn}
              onClick={this.onSubmit}
              className="login_btn"
            >
              Next
            </Button>
            {/* <p
              style={{
                margin: 0,
                fontSize: FONTS.size_minimum,
                marginTop: "5px",
              }}
            >
              Don't have an account?{" "}
              <span
                className="fontBold"
                style={{ color: COLORS.juma_theme_clr, cursor: "pointer" }}
              >
                Sign Up
              </span>
            </p> */}
            <div
              style={{
                margin: 0,
                fontSize: FONTS.size_minimum,
                marginTop: "5px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              {" "}
              Don't have an account?{" "}
              <div
                onClick={this.goToSignIn}
                className="fontBold"
                style={{
                  color: COLORS.juma_theme_clr,
                  cursor: "pointer",
                  marginLeft: 3,
                }}
              >
                Sign Up
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
