import React from "react";
import { connect } from "react-redux";
import { signUp, validateCode } from "../../store/actions/auth";
import { validateFields } from "../../utils/commonFunc";
import SignUp from "../../views/registration/signup";

class _SignUp extends SignUp {
  async componentDidMount() {
    const params = this.props.history.location.search
      ? decodeURIComponent(this.props.history.location.search.slice(1))
          .split(["&"])
          .reduce((prev, item) => {
            let arr = item.split("=");
            return { ...prev, [arr[0]]: JSON.parse(arr[1]) };
          }, {})
      : {};
    this.setState({ paramsData: params });
  }

  onInputChange = (key) => (e) => {
    this.setState({ [key]: { value: e.target.value } });
  };

  handleDateChange = (key) => (date) => {
    if (isNaN(Date.parse(date))) {
      this.setState({
        [key]: {
          value: date,
          isError: true,
          errorMessage: `DOB should be in a valid format`,
        },
      });
      return;
    }
    console.log(key, "---->date");
    this.setState({
      [key]: { value: date },
    });
  };

  signUp = async () => {
    const { paramsData, loadComponent, dob, ...rest } = this.state;
    const merchantInfo = Object.keys(rest).reduce((prev, key) => {
      return { ...prev, [key]: rest[key].value };
    }, {});
    const payload = {
      ...paramsData,
      dob: new Date(dob.value),
      ...merchantInfo,
    };
    const error = validateFields(payload, (key) => {
      this.setState({
        [key]: {
          ...this.state[key],
          isError: true,
          errorMessage: `${key} is required`,
        },
      });
    });
    if (!error) {
      let res = (await this.props.signUp(payload)) || {};
      if (!!res.success) {
        this.props.history.push(`email-otp`);
      }
    }
  };
}

export default connect(null, { signUp, validateCode })(_SignUp);
