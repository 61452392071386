import { Redirect, Route, Switch } from "react-router";
import React from "react";
import Rewards from "../controllers/rewards/rewards";
import Balance from "../controllers/balance/balance";
import CreditNotes from "../views/credit-notes";

export class RouterOutLet extends React.Component {
  render() {
    const { Routes, exactPath } = this.props;
    return (
      <Switch>
        {Routes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            component={route.component}
          />
        ))}
        <Route
          path={`${exactPath.path}`}
          exact
          render={(props) => <Redirect to={exactPath.to} />}
        />
      </Switch>
    );
  }
}

export default class MyDashboardRoutes extends React.Component {
  render() {
    const { path } = this.props.computedMatch;
    const Routes = [
      { path: `${path}/set-rewards`, component: Rewards },
      { path: `${path}/balance`, component: Balance },
      { path: `${path}/credits`, component: CreditNotes },
    ];
    return (
      <RouterOutLet
        Routes={Routes}
        exactPath={{ path, to: `${path}/balance` }}
      />
    );
  }
}
