import React from "react";
import { SIDENAV_ITEMS } from "../constants";
import { COLORS } from "../constants";
import { FONTS } from "../constants";

export default class LeftPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leftBar: [...SIDENAV_ITEMS],
      activeMenu: "Set Rewards",
    };
  }

  componentDidMount() {
    const { pathname = "" } = this.props.location;
    const screen = this.state.leftBar.find((item) =>
      pathname.includes(item.keyRoute)
    );
    this.setState({ activeMenu: screen ? screen.label : "Balance" });
  }

  render() {
    const {
      leftNav,
      itemsContainer,
      eachNavItem,
      activeNavItem,
      navText,
      collapseIcon,
    } = leftNavStyles;
    const { leftBar } = this.state;
    const { path } = this.props;
    return (
      <div style={leftNav} className = "sideNavbar">
        <div style={itemsContainer} class="">
          <p style={collapseIcon}>
            <img src="/images/collapse.svg" style={{ cursor: "pointer" }} />
          </p>
          {leftBar.map(({ activeImage, inactive, label, keyRoute }) => (
            <div
              onClick={() => {
                this.props.history.push(`${path}${keyRoute}`);
                this.setState({ activeMenu: label });
              }}
              key={label}
              style={{
                ...eachNavItem,
                ...(this.state.activeMenu === label ? activeNavItem : {}),
              }}
            >
              <img
                src={this.state.activeMenu === label ? activeImage : inactive}
              />
              <p style={navText}>{label}</p>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export const leftNavStyles = {
  leftNav: {
    position: "fixed",
    marginTop: "65px",
    top: "0",
    left: "0",
    bottom: "0",
    width: "200px",
    boxShadow: "0 0 6px #ccc",
    paddingTop: "55px",
    backgroundColor: COLORS.default_white,
  },
  // const navItemStyles = {
  //     inactiveItems : {
  //         position:"fixed",
  //         marginTop : "65px",
  //         top : "0",
  //         left : "0",
  //         bottom : "0",
  //         width : "200px",
  //         boxShadow :"0 0 6px #ccc",
  //         paddingTop: "50px",
  //     },
  //     activeItems : {
  //         color : COLORS.juma_theme_clr,
  //     }
  // }
  eachNavItem: {
    display: "flex",
    padding: "2px 10px 2px 15px",
    alignItems: "center",
    fontSize: FONTS.size_normal,
    cursor: "pointer",
  },
  navText: {
    margin: "12px 0",
    marginLeft: "10px",
    fontSize: FONTS.size_medium,
  },
  activeNavItem: {
    color: COLORS.juma_theme_clr,
    fontFamily: "NotoSans-Bold",
    backgroundColor: "#e8f1fe",
    borderRight: "3px solid",
    borderColor: COLORS.juma_theme_clr,
  },
  itemsContainer: {
    position: "relative",
  },
  collapseIcon: {
    position: "absolute",
    top: "-50px",
    right: "-10px",
  },
};
