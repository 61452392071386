import { CALL_API } from "../api.middleware";
import {
  CREATE_MERCHANT,
  CREATE_MERCHANT_SUCCESS,
  GET_BUSSINESS_CATEGORY,
  GET_BUSSINESS_CATEGORY_SUCCESS,
  LOGIN,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  SIGN_UP,
  SIGN_UP_SUCCESS,
  VALIDATE_CODE,
  VALIDATE_CODE_SUCCESS,
  VALIDATE_EXPERIAN,
  VALIDATE_EXPERIAN_SUCCESS,
} from "../types/auth";

export const onLogin = (body) => async (dispatch) => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/merchant/login`,
        showLoader: true,
        method: "POST",
        types: [LOGIN, LOGIN_SUCCESS],
        body,
      },
    });
  } catch (error) {
    alert(`${error}`);
  }
};

export const onLogout = () => {
  return { type: LOGOUT_SUCCESS };
};

export const createMerchant = (body) => async (dispatch) => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/merchant/create_merchant`,
        showLoader: true,
        method: "POST",
        types: [CREATE_MERCHANT, CREATE_MERCHANT_SUCCESS],
        body,
      },
    });
  } catch (error) {
    alert(`${error}`);
  }
};
export const getBussinessCategory = (body) => async (dispatch) => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/merchant/bussiness-category`,
        showLoader: true,
        method: "GET",
        types: [GET_BUSSINESS_CATEGORY, GET_BUSSINESS_CATEGORY_SUCCESS],
        body,
      },
    });
  } catch (error) {
    alert(`${error}`);
  }
};
export const signUp = (body) => async (dispatch) => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/merchant/signUp`,
        showLoader: true,
        method: "POST",
        types: [SIGN_UP, SIGN_UP_SUCCESS],
        body,
      },
    });
  } catch (error) {
    alert(`${error}`);
  }
};

export const validateCode = (body, otpToken) => async (dispatch) => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/merchant/verify-otp/${otpToken}`,
        showLoader: true,
        method: "POST",
        types: [VALIDATE_CODE, VALIDATE_CODE_SUCCESS],
        body,
      },
    });
  } catch (error) {
    alert(`${error}`);
  }
};

export const validateExperian = (body) => async (dispatch) => {
  try {
    return await dispatch({
      [CALL_API]: {
        url: `/merchant/experian_verify`,
        showLoader: true,
        method: "POST",
        types: [VALIDATE_EXPERIAN, VALIDATE_EXPERIAN_SUCCESS],
        body,
      },
    });
  } catch (error) {
    alert(`${error}`);
  }
};
